import React, {
  useContext,
  useEffect,
  useState,
} from "react"
import { graphql, navigate } from "gatsby"
import SEO from "../components/seo"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import { useMediaQuery } from "react-responsive"
import Filter from "../components/filter/Filter"
import CardsSliders from "../components/common/cards-sliders/CardsSliders"
import CardsList from "../components/common/cardsList/CardsList"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

let Courses = ({ data }) => {
  const [isFilterOpen, setisFilterOpen] = useState(false)
  const [withSearchList, setWithSearchList] =
    useState(false)

  const [hasActiveFilters, setHasActiveFilters] =
    useState(false)

  const [isMobileFilterOpen, setisMobileFilterOpen] =
    useState(false)

  const [isMobileView, setisMobileView] = useState(false)
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  })

  const filterCourses = () => {
    const categories = state.filtersObject.categories
    const prices = state.filtersObject.prices
    const activeFilterNames = state.selectedFilterOptions

    const latestFilter = activeFilterNames.slice(-1)[0]

    setHasActiveFilters(true)

    const totalCategoryFiltersApplied = Object.values(
      categories
    ).filter((value) => value === true).length

    const totalPricesFiltersApplied = Object.values(
      prices
    ).filter((value) => value === true).length

    const hasAnyCategoryFilter = Object.values(
      categories
    ).some((cat) => cat === true)
    const hasAnyPriceFilter = Object.values(prices).some(
      (cat) => cat === true
    )

    const allCourses = state.courses

    let filteredCourses =
      state.filteredCoursesList?.length > 0
        ? state.filteredCoursesList
        : []

    let baseCourses = []

    if (hasAnyCategoryFilter && hasAnyPriceFilter) {
      baseCourses = filteredCourses
    } else if (
      (hasAnyCategoryFilter && !hasAnyPriceFilter) ||
      (!hasAnyCategoryFilter && hasAnyPriceFilter)
    ) {
      baseCourses = allCourses
    } else {
      setHasActiveFilters(false)
    }

    let filtered
    activeFilterNames.map((filterProp) => {
      filtered = baseCourses.filter((course) => {
        if (filterProp === "all") {
          return course
        } else if (filterProp === "autodesk") {
          if (course.category) {
            return course.category.includes("autodesk")
          }
        } else if (filterProp === "bentley") {
          if (course.category) {
            return course.category.includes("bentley")
          }
        } else if (filterProp === "free") {
          if (course.product?.netFee) {
            return course.product?.netFee === 0
          } else {
            return course.product?.price === 0
          }
        } else if (filterProp === "0To49") {
          if (course.product?.netFee) {
            return (
              course.product?.netFee > 0 &&
              course.product?.netFee <= 49
            )
          } else {
            return (
              course.product?.price > 0 &&
              course.product?.price <= 49
            )
          }
        } else if (filterProp === "50To99") {
          if (course.product?.netFee) {
            return (
              course.product?.netFee >= 50 &&
              course.product?.netFee <= 99
            )
          } else {
            return (
              course.product?.price >= 50 &&
              course.product?.price <= 99
            )
          }
        } else if (filterProp === "100To499") {
          if (course.product?.netFee) {
            return (
              course.product?.netFee >= 100 &&
              course.product?.netFee <= 499
            )
          } else {
            return (
              course.product?.price >= 100 &&
              course.product?.price <= 499
            )
          }
        } else if (filterProp === "500To999") {
          if (course.product?.netFee) {
            return (
              course.product?.netFee >= 500 &&
              course.product?.netFee <= 999
            )
          } else {
            return (
              course.product?.price >= 500 &&
              course.product?.price <= 999
            )
          }
        } else if (filterProp === "moreThan1000") {
          if (course.product?.netFee) {
            return course.product?.netFee > 999
          } else {
            return course.product?.price > 999
          }
        } else {
          return course
        }
      })
    })

    if (latestFilter === "autodesk") {
      filteredCourses = filtered
    } else if (
      (latestFilter === "autodesk" ||
        latestFilter === "bentley") &&
      totalCategoryFiltersApplied > 1
    ) {
      filteredCourses.push(...filtered)
    } else if (
      (latestFilter === "free" ||
        latestFilter === "0To49" ||
        latestFilter === "50To99" ||
        latestFilter === "100To499" ||
        latestFilter === "500To999" ||
        latestFilter === "moreThan1000") &&
      totalPricesFiltersApplied > 1
    ) {
      filteredCourses.push(...filtered)
    } else {
      filteredCourses = filtered
    }

    dispatch({
      type: "SET_FILTERED_COURSES",
      payload: filteredCourses,
    })
  }

  useEffect(() => {
    if (state.selectedFilterOptions) {
      filterCourses()
    }
  }, [state?.selectedFilterOptions, state?.filtersObject])

  useEffect(() => {
    const setViewStatus = () => {
      setisMobileView(isMobile)
    }
    setViewStatus()

    return () => {
      setisMobileView(false)
    }
  }, [isMobile])

  const isBrowser = typeof window !== "undefined"
  const windowObj = isBrowser ? window : null

  useEffect(() => {
    const setViewOption = () => {
      if (window.location.search.includes("searchquery")) {
        setWithSearchList(true)
      } else {
        dispatch({
          type: "SET_COURSE_SEARCH_RESULTS",
          payload: [],
        })

        setWithSearchList(false)
      }
      if (
        window.location.search.includes("filter") ||
        window.location.search.includes("searchquery")
      ) {
        setisFilterOpen(true)
      }
    }
    if (isBrowser) {
      setViewOption()
    }
    return () => {
      setisFilterOpen(false)
    }
  }, [isBrowser, windowObj?.location.search])

  const handleFilterClick = () => {
    setisFilterOpen(true)

    if (isMobileView) {
      setisMobileFilterOpen(true)
    }
  }
  const closeFilter = () => {
    setisMobileFilterOpen(false)
  }

  const handleClearFilters = () => {
    dispatch({
      type: "SET_FILTERED_COURSES",
      payload: [],
    })
    dispatch({
      type: "CLEAR_FILTER_VALUES",
    })

    dispatch({
      type: "SET_FILTER_OPTIONS",
      payload: [],
    })
    setWithSearchList(false)
    navigate("/courses")
  }

  return (
    <>
      <SEO
        title="All BIM & CAD Modeling Courses | BIM & CAD Training Center"
        description="Ace Industrial Academy conducts BIM courses for Autocad 2D/3D, Revit, Synchro 4D, OpenPlant, and more"
      />
      <TitleBannerEnquiry
        title="All Courses"
        description="We conduct trainings with BIM Courses for AutoCad, Revit, ProjectWise360, BIM360 and many more"
        handleFilterClick={handleFilterClick}
        isFilterOpen={isFilterOpen}
      />

      {isFilterOpen ? (
        <div className="coursesList">
          {isMobileView ? (
            <>
              {isMobileFilterOpen && (
                <Filter
                  closeFilter={closeFilter}
                  handleClearFilters={handleClearFilters}
                />
              )}
            </>
          ) : (
            <Filter
              closeFilter={closeFilter}
              handleClearFilters={handleClearFilters}
            />
          )}

          <CardsList
            withSearchList={withSearchList}
            courses={data?.courses.nodes}
            hasActiveFilters={hasActiveFilters}
          />
        </div>
      ) : (
        <CardsSliders courses={data?.courses.nodes} />
      )}
    </>
  )
}

export const query = graphql`
  query {
    courses: allContentfulTraining {
      nodes {
        id
        category
        title
        courseReference
        trainingMode
        updatedAt
        slug
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default Courses
